import { Box, Typography } from "@mui/material";
import React from "react";
import CategoryIcon from "@mui/icons-material/Category";

const LeftNav = () => {
    return (
        <Box>
            <Typography
                sx={{
                    display: "flex",
                    alignItems: "center",
                    bgcolor: "#555",
                    p: 2,
                    gap: 1,
                    ":hover": {
                        bgcolor: "#666",
                        cursor: "pointer",
                    },
                }}
            >
                <CategoryIcon />
                <span>Categories</span>
            </Typography>
        </Box>
    );
};

export default LeftNav;
