import { Toaster } from "react-hot-toast";
import "./App.css";
import Layout from "./components/Layout";
import AppContextProvider from "./context/appContext";

function App() {
    return (
        <div>
            <AppContextProvider>
                <Toaster
                    position="top-right"
                    reverseOrder={false}
                />

                <Layout />
            </AppContextProvider>
        </div>
    );
}

export default App;
