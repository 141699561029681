import React, { useContext, useEffect, useState } from "react";
import {
    Button,
    TextField,
    Box,
    TableContainer,
    Table,
    Paper,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { AppContext } from "../context/appContext";
import axios from "axios";
import toast from "react-hot-toast";

const ManageCategory = () => {
    const { allCategories, loading, setLoading, setAllCategories } =
        useContext(AppContext);

    // internal states
    const [categoryName, setCategoryName] = useState("");
    const [editCategoryName, setEditCategoryName] = useState("");
    const [selectedCategoryId, setSelectedCategoryId] = useState(null); // To store the ID of the selected category for update/delete

    // handling events on change
    const handleCategoryNameChange = (event) => {
        setCategoryName(event.target.value);
    };
    const handleEditCategoryNameChange = (event) => {
        setEditCategoryName(event.target.value);
    };

    // edit/update category
    const handleEditCategory = (categoryId) => {
        setSelectedCategoryId(categoryId);
    };

    const handleUpdateCategory = async (categoryId) => {
        if (!editCategoryName) {
            toast.error("Invalid category name");
            return;
        }
        const currentCategory = allCategories.find(
            (category) => category.cat_id === categoryId
        );
        console.log("current category", currentCategory);
        if (currentCategory.cat_name === editCategoryName) {
            toast.error("Same category name can't be updated.");
            return;
        }
        try {
            const response = await axios.put(
                process.env.REACT_APP_BASE_URL + `/api/update_cat/`,
                {
                    cat_id: categoryId,
                    cat_name: editCategoryName,
                }
            );
            if (response.status === 200) {
                fetchAllCategories();
                toast.success(response?.data?.data?.message);
                setSelectedCategoryId(null); // Clear the selected category
                setCategoryName(""); // Clear the input field
            }
        } catch (err) {
            toast.error(err?.response?.data?.data?.message);
        }
    };

    const handleCancelEdit = () => {
        setSelectedCategoryId(null);
        setEditCategoryName(""); // Clear the input field
    };

    // add category
    const addCategory = async (e) => {
        e.preventDefault();
        if (!categoryName) {
            toast.error("Please enter category name");
        } else {
            try {
                const response = await axios.post(
                    process.env.REACT_APP_BASE_URL + "/api/add_cat/",
                    {
                        cat_name: categoryName,
                    }
                );
                if (response.status === 201) {
                    fetchAllCategories();
                }
                toast.success(response?.data?.data?.message);
                console.log(response);
                setCategoryName("");
            } catch (err) {
                console.log("error", err);
                toast.error(err?.response?.data?.data?.message);
            }
        }
    };

    // delete category
    const handleDeleteCategory = async (categoryId) => {
        try {
            const response = await axios.delete(
                process.env.REACT_APP_BASE_URL + `/api/delete_cat/`,
                { params: { cat_id: categoryId } }
            );
            if (response.status === 200) {
                fetchAllCategories();
                toast.success(response?.data?.data?.message);
            }
        } catch (err) {
            toast.error(err?.response?.data?.data?.message);
        }
    };

    // method to fetch all the categories
    const fetchAllCategories = async () => {
        try {
            setLoading(true);
            const response = await axios.get(
                process.env.REACT_APP_BASE_URL + "/api/get_allcat"
            );
            const data = response?.data?.data?.req_data;
            setAllCategories(data);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            console.log("Error occured while fetching all the categories", err);
        }
    };

    useEffect(() => {
        fetchAllCategories();
    }, []);

    return (
        <Box>
            <form
                onSubmit={addCategory}
                style={{
                    display: "flex",
                    gap: 10,
                }}
            >
                <TextField
                    variant="outlined"
                    label="Enter Category Name"
                    value={categoryName}
                    onChange={handleCategoryNameChange}
                />

                <Button
                    type="submit"
                    variant="contained"
                    sx={{
                        bgcolor: "#555",
                        ":hover": {
                            bgcolor: "#777",
                        },
                    }}
                >
                    Add Category
                </Button>
            </form>
            <Box mt={5}>
                <TableContainer
                    component={Paper}
                    sx={{
                        maxHeight: "80vh",
                        width: { xs: "100%", md: "700px", lg: "900px" },
                        border: "1px solid #ddd",
                    }}
                >
                    <Table
                        stickyHeader
                        aria-label="simple table"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    sx={{
                                        bgcolor: "#555",
                                        color: "#fff",
                                        fontWeight: 900,
                                    }}
                                >
                                    SL. No.
                                </TableCell>
                                <TableCell
                                    sx={{
                                        bgcolor: "#555",
                                        color: "#fff",
                                        fontWeight: 900,
                                    }}
                                >
                                    Category Name
                                </TableCell>
                                <TableCell
                                    sx={{
                                        bgcolor: "#555",
                                        color: "#fff",
                                        fontWeight: 900,
                                    }}
                                >
                                    Sub-Categories Associated
                                </TableCell>
                                <TableCell
                                    sx={{
                                        bgcolor: "#555",
                                        color: "#fff",
                                        fontWeight: 900,
                                    }}
                                >
                                    Actions
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        {loading ? (
                            <TableBody>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Box className="custom-loader"></Box>
                                    </TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableBody>
                        ) : (
                            <TableBody>
                                {allCategories?.map((category, index) => (
                                    <TableRow
                                        key={category?.cat_id}
                                        sx={{
                                            "&:last-child td, &:last-child th":
                                                {
                                                    border: 0,
                                                },
                                        }}
                                    >
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                        >
                                            {selectedCategoryId ===
                                            category.cat_id ? (
                                                <TextField
                                                    variant="outlined"
                                                    label="Category Name"
                                                    value={editCategoryName}
                                                    onChange={
                                                        handleEditCategoryNameChange
                                                    }
                                                />
                                            ) : (
                                                category?.cat_name
                                            )}
                                        </TableCell>
                                        <TableCell>hello</TableCell>
                                        <TableCell>
                                            {selectedCategoryId ===
                                            category.cat_id ? (
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        gap: 3,
                                                    }}
                                                >
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() =>
                                                            handleUpdateCategory(
                                                                category.cat_id
                                                            )
                                                        }
                                                    >
                                                        Edit
                                                    </Button>
                                                    <Button
                                                        variant="contained"
                                                        color="secondary"
                                                        onClick={
                                                            handleCancelEdit
                                                        }
                                                    >
                                                        Cancel
                                                    </Button>
                                                </Box>
                                            ) : (
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        gap: 3,
                                                    }}
                                                >
                                                    <Button
                                                        onClick={() =>
                                                            handleEditCategory(
                                                                category.cat_id
                                                            )
                                                        }
                                                    >
                                                        <EditIcon
                                                            sx={{
                                                                color: "blue",
                                                            }}
                                                        />
                                                    </Button>
                                                    <Button
                                                        onClick={() =>
                                                            handleDeleteCategory(
                                                                category.cat_id
                                                            )
                                                        }
                                                    >
                                                        <DeleteIcon
                                                            sx={{
                                                                color: "red",
                                                            }}
                                                        />
                                                    </Button>
                                                </Box>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    );
};

export default ManageCategory;
