import React from "react";
import ManageCategory from "./ManageCategory";
import { Box } from "@mui/material";

const RightSide = () => {
  return (
    <Box sx={{ p: 2 }}>
      <ManageCategory />
    </Box>
  );
};

export default RightSide;
