import React from "react";
import LeftNav from "./LeftNav";
import RightSide from "./RightSide";
import { Box } from "@mui/material";

const Layout = () => {
  return (
    <Box sx={{ display: "flex", height: "100vh", overflow: "hidden" }}>
      <Box sx={{ width: "20vw", bgcolor: "#37474f", color: "#FFFFFF", p: 2 }}>
        <LeftNav />
      </Box>
      <div className="w-[70vw] p-3">
        <RightSide />
      </div>
    </Box>
  );
};

export default Layout;
