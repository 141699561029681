import axios from "axios";
import { createContext, useEffect, useState } from "react";

export const AppContext = createContext();

const AppContextProvider = ({ children }) => {
    const [allCategories, setAllCategories] = useState(null);
    const [loading, setLoading] = useState(false);

    return (
        <AppContext.Provider
            value={{ allCategories, loading, setAllCategories, setLoading }}
        >
            {children}
        </AppContext.Provider>
    );
};

export default AppContextProvider;
